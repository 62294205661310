<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>Namn</th>
          <th>KT</th>
          <th>GT</th>
          <th>FiG</th>
          <th>JAG</th>
          <th>FGSY</th>
          <th>SLUS</th>
          <th>KMA</th>
          <th>MEG</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in list" :key="item._id">
          <td>{{ item.name }}</td>
          <td>{{ coursedate("KT", item) }}</td>
          <td>{{ coursedate("GT", item) }}</td>
          <td>{{ coursedate("FIG", item) }}</td>
          <td>{{ coursedate("JAG", item) }}</td>
          <td>{{ coursedate("FGSY", item) }}</td>
          <td>{{ coursedate("SLUS", item) }}</td>
          <td>{{ coursedate("KMA", item) }}</td>
          <td>{{ coursedate("MEG", item) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
table {
  border-bottom-color: #e3e3e3;
  border-top-color: #e3e3e3;
  border-right-color: #e3e3e3;
  border-left-color: #e3e3e3;
}
thead tr {
  background-color: rgb(141, 83, 154);
  color: white;
  font-weight: bold;
}
</style>

<script>
export default {
  props: {
    url: {
      type: String,
      default: "/api_golvbranchen?query=employees_and_courses",
    },
  },
  data: function () {
    return {
      list: [],
    };
  },
  methods: {
    coursedate: function (coursename, item) {
      const findCourse = item.courses.find(
        (t) => t.coursetype == "GBR - " + coursename
      );
      if (typeof findCourse == "undefined") return "";
      return findCourse.date;
    },
    getUsers: function () {
      try {
        fetch(this.url, {
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            this.list = data;
          });
      } catch (e) {
        console.log("error");
      }
    },
  },
  mounted: function () {
    this.getUsers();
  },
};
</script>

<style>
</style>